<template>
    <div>
        
        <el-tabs v-model="activeName" @tab-click="handleClick">

            <el-tab-pane label="提成汇总" name="first">
          
                <page-table :activeName="activeName" :tableList="tableList" @fetchData="fetchData" :rowList="rowList" :employeeList="employeeList" :shopList="shopList" :typeList="typeList"></page-table>

            </el-tab-pane>

            <el-tab-pane label="提成明细表" name="second">

                 <page-table :activeName="activeName" :tableList="tableList" @fetchData="fetchData" :rowList="rowList" :employeeList="employeeList" :shopList="shopList" :typeList="typeList"></page-table>

            </el-tab-pane>

        </el-tabs>

        <div class="pagina">
            <el-pagination
                    :current-page="page"
                    background
                    @size-change="handleSizeChange"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total"
                    :page-size="$store.state.pageSizes[0]"
                    :page-sizes="$store.state.pageSizes"
                    @current-change="handleCurrentChange"
                ></el-pagination>
        </div> 

    </div>
</template>


<script>
import Cond from '@/components/cond/Index.vue';
import TableConf from '@/components/tableConf/Index.vue';
import pageTable from './components/pageTable.vue'
import {reportCommission,getEmployeeList,getTypeList,getMerchantList } from '@/api/order/offlineOrder/commission'
export default{
    components:{Cond,TableConf,pageTable},
    created(){
        this.reportCommission()
        this.getEmployeeList()
        this.getMerchantList()
        this.getTypeList()
    },
    mounted(){
        this.$nextTick(() => {
            const dom = document.getElementById("cond-middle");
            this.offsetHeight = dom.offsetHeight + "px";
            console.log(this.offsetHeight)
          });
    },
    data(){
        return{
            activeName:'first',
            rowList:[],
            tableList:[],
            page:1,
            limit:50,
            cond:{},
            employeeList:[], //销售
            shopList:[], //门店
            typeList:[], //品类
            form:{
                start_time:'',
                end_time:'',
                sold_user_ids:'',
                hierarchy_id:'',
                goods_type_id:'',
                order_num:'', //单据号
                goods_number:'',//货号
                recycle_type:'',
            },
            total:10,
        }
    },
    methods:{

        fetchData(e){
            this.form={...e.obj}
            this.reportCommission()
        },

         // 改变分页条数
        handleSizeChange(size) {
            this.limit = size;
            this.reportCommission();
        },

        handleCurrentChange(page) {
            this.page = page;
            this.reportCommission();
        },

         //销售提成列表
         reportCommission(){
            const {start_time,end_time,sold_user_ids,hierarchy_id,goods_type_id,goods_number,order_num}= this.form
            let user_ids=sold_user_ids?sold_user_ids.toString():''
            reportCommission({
                start_time,
                end_time,
                hierarchy_id,
                sold_user_ids:user_ids,
                goods_type_id,
                page: this.page, 
                limit: this.limit,
                goods_number,
                order_num,
                type:this.activeName=='first'?1:2,
                recycle_type:'',
            })
            .then((res) => {
            if (res.code === 1) {
                this.tableList = res.data.list;
            }
            })
        },

        //获取品类列表
        getTypeList(){
            getTypeList().then(res=>{
                if(res.code==1){
                    this.typeList=res.data
                }
            })
        },  

        //获取门店列表
        getMerchantList(){
            getMerchantList().then(res=>{
                if(res.code==1){
                   let shopList= res.data
                   shopList.map(item=>{
                        item.hierarchy_id=Number.parseInt(item.hierarchy_id)
                    })
                    this.shopList=shopList
                }
            })
        },

        // 获取销售员列表
        getEmployeeList(){
            getEmployeeList().then(res=>{
                if(res.code==1){
                    this.employeeList=res.data
                }
            })
        },

        handleClick(tab, event) {
            this.form={
                start_time:'',
                end_time:'',
                hierarchy_id:'',
                order_num:'',
                goods_number:'',
                goods_type_id:'',
            }
            this.reportCommission()
        },

    }
}
</script>

<style lang='less' scoped>
.flex{display:flex;}
.formFlex{
    display: flex;flex-wrap: wrap;
}

.wrapper {
  transition: all 0.3s;
  overflow: hidden;
  .middle {
    display: flex;
    overflow: hidden;
  }
}
</style>